@tailwind base;
@tailwind components;
@tailwind utilities;

span {
  white-space: nowrap;
}

.heading-text.heading-section h2::before {
  content: unset;
}

heading-text.heading-line h1::before,
.heading-text.heading-line h2::before,
.heading-text.heading-line h3::before,
.heading-text.heading-line h4::before,
.heading-text.heading-line h5::before,
.heading-text.heading-line h6::before {
  content: unset !important;
}

.step_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8b2a;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: medium;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.icon-box.border.small .icon {
  height: 22px;
  width: 22px;
}

.icon-box.medium > p {
  margin: 0 12px 10px 36px;
}

.icon-box.medium > h3,
.icon-box.medium > h4,
.icon-box.medium > h5 {
  margin: 0 12px 10px 36px;
}
.icon-box.border:hover > .icon {
  border: 6px solid #ff8b2a;
}

@media only screen and (max-width: 990px) {
  .step_paragraph {
    display: none;
  }

  .step_title {
    overflow-wrap: anywhere;
  }

  .authenticate-btn {
    display: none;
  }
}

@media only screen and (min-width: 990px) {
  .authenticate-btn_active {
    display: none;
  }
}
