.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

/* light mode */

.suggestions {
  border: 1px solid #e4e6ef;
  border-radius: 4px;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  font-weight: 500;
}

/* dark mode */

.suggestions-dark {
  background-color: #fff;
  border: 1px solid #e4e6ef;
  border-radius: 4px;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions-dark li {
  padding: 0.5rem;
  color: black !important;
}

.suggestion-active,
.suggestions-dark li:hover {
  background-color: #ececec;
  color: #1e2022;
  cursor: pointer;
  font-weight: 500;
}
