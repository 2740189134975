.h6 {
  margin: 40px;
  border: 5px dotted pink;
}

.dark-grey {
  background-color: #3d4045;
}

.light-grey {
  background-color: #505358;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-box:hover > div > i,
.text-box:hover > div > h3 {
  display: none !important;
}

.text-box:hover > div > p {
  display: block !important;
}

.background-fit {
  background-size: cover;
  background-repeat: no-repeat;
}
