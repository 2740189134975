#whatwedo {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: none;
}

@media screen and (max-width: 600px) {
  #whatwedo {
    background-image: none !important;
    padding-bottom: 25px;
    padding-top: 0px;
  }
}

.heading-text.heading-section h2 {
  margin-bottom: 22px !important;
}
