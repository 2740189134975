.avatar {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 990px) {
  #search-btn {
    width: 100%;
    text-align: center;
  }
}

.line {
  margin: 0 !important;
}

.temp {
  color: black;
}
