.step_container {
  font-family: unset;
  background-color: unset;
  border: 1px solid #ff8b2a;
  color: #ff8b2a !important;
}

@media only screen and (max-width: 767px) {
  .timeline_item::before {
    content: unset !important;
  }
}
