form p {
  color: red;
  font-size: 13px;
}

.msg {
  color: green;
  display: none;
}

.msgAppear {
  display: block;
}

.number {
  height: 40px;
}

.country {
  border: 1px solid rgb(211, 211, 211);
  padding-top: 4px;
}

@media (max-width: 667px) {
  .country {
    max-width: 314px;
    border-radius: 10px;
    border: 1px solid rgb(228, 225, 225);
  }

  .codeandnumber {
    display: flex;
    flex-direction: row;
  }

  .contries {
    max-width: 500px;
  }
}

.lighText {
  color: "white" !important;
}
